<template>
  <v-toolbar
    flat
  >
    <v-toolbar-title>{{toolbarTitle}}</v-toolbar-title>
    <v-divider
      class="mx-4" inset vertical
    ></v-divider>
     <!-- can override to show other tools here -->
    <slot name="additionalTools" />
    <slot name="selectDate"></slot>
    <slot name="activator" />
    <slot name="bulkMarking"></slot>
    <slot name="duplicateMarking"></slot>
    <slot name="canDeleteSlot"></slot>
    <slot name="additionalToolsAtRight"></slot>
    <v-spacer></v-spacer>
    <!-- New/Edit item dialog -->
    <slot name="canAddSlot" />
  </v-toolbar>
</template>

<script>
import {
  resolveSubProp,
} from '../../../../util/shared/vue-global';

export default {
  name: 'CRUDToolbar',
  props: {
    toolbarTitle: {
      type: String,
      default: 'Items',
    },
  },
  computed: {
    resolveSubProp,
  },
};

</script>
