<template>
  <div>
    <MiscExpandableRow
      v-if="baseCrudKey === 'miscTransaction'"
      :item="item"
    />
    <span v-else>(Write a custom expandable row as per need.)</span>
  </div>
</template>

<script>
import MiscExpandableRow from '../../../Misc/Transaction/MiscExpandableRow.vue';

export default {
  name: 'CRUDExpandableRow',
  components: {
    MiscExpandableRow,
  },
  data: () => ({}),
  props: {
    baseCrudKey: {
      type: String,
      default: 'CRUDExpandableRowKey',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {},
  methods: {},
};

</script>
