<template>
  <v-simple-table dense
    class="misc-expandable-row-table"
    id="miscExpandableRowTable"
  >
      <thead>
          <tr>
            <th
              v-for="(item, index) in tableHeader"
              :key="'table-header-' + index"
              :class="item.cssClass"
            >
              {{ item.title }}
            </th>
          </tr>
      </thead>
      <tbody>
          <tr
            v-for="(item, index) in ((updatedTrans || {}).details || [])"
            :key="'table-row-' + index"
          >
            <td>{{ item.fundAndDesc }}</td>
            <td>{{ item.sourceSubSourceNumber }}</td>
            <td>{{ item.sourceDescription }}</td>
            <td>{{ item.unitCodeAndDesc }}</td>
            <td style="text-align: right">{{ amountFormat(item.amount) }}</td>
            <td v-if="index === 0"
              :rowspan="(updatedTrans.details || []).length"
            >
                {{ updatedTrans.description }}
            </td>
          </tr>
      </tbody>
  </v-simple-table>
</template>

<script>
import { amountFormat } from '../../../util/shared/vue-global';

export default {
  name: 'MiscExpandableRow',
  data: () => ({
    tableHeader: [
      { title: 'Fund', cssClass: 'blue lighten-5' },
      { title: 'Source', cssClass: 'blue lighten-5' },
      { title: 'Source Description', cssClass: 'blue lighten-5' },
      { title: 'Unit Code', cssClass: 'blue lighten-5' },
      { title: 'Amount', cssClass: 'blue lighten-5' },
      { title: 'Description', cssClass: 'blue lighten-5' },
    ],
  }),
  props: {
    baseCrudKey: {
      type: String,
      default: 'MiscExpandableRowKey',
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    updatedTrans() {
      const updatedItem = { ...this.item };
      updatedItem.details = ((updatedItem || {}).details || []).map((d) => ({
        fundAndDesc: `${((d.fund || {}).fund || '')}-${((d.fund || {}).description || '')}`,
        sourceSubSourceNumber: `${(d.source || {}).sourceSubSourceNumber || ''}`,
        sourceDescription: `${(d.source || {}).sourceDescription || ''} - ${(d.source || {}).subSourceDescription || ''}`,
        unitCodeAndDesc: `${(d.unit || {}).unitCodeNumber || ''} - ${(d.unit || {}).description || ''}`,
        ...d,
      }));
      return updatedItem;
    },
  },
  methods: {
    amountFormat,
  },
};

</script>
