<template>
  <div
  v-if="customItemTotal"
    class="table-footer-prepend"
    >
    {{customItemTotal}}
  </div>
  <div
    v-else
    :class="itemTotal < 0 ? 'red--text' : '' "
    class="table-footer-prepend"
    >
    {{`Total: ${amountFormat(itemTotal)}`}}
  </div>
</template>

<script>
import { amountFormat } from '../../../../util/shared/vue-global';

export default {
  name: 'CRUDListTotal',
  props: {
    itemTotal: {
      type: Number,
      default: 0,
    },
    customItemTotal: {
      type: String,
      default: '',
    },
  },
  methods: {
    amountFormat,
  },
};
</script>

<style lang="sass">
  .table-footer-prepend
    margin: 0px 0px -45px 45px
</style>
