<template>
  <div class="elevation-3 ma-3 pa-3">
    <v-data-table
      v-model="selectedTasks"
      :item-key="itemKey"
      :headers="headers"
      :items="itemsGetter"
      :items-per-page.sync="getSettings.itemsperpage"
      :server-items-length="getSettings.serverItemsLength"
      :footer-props="getSettings.footerProps"
      @update:options="handleOptions"
      dense
      :show-expand="showExpand"
      :expanded.sync="expanded"
      :show-select="showSelect"
      :loading="loadingData"
      :loading-text="loadingText"
      class="elevation-1"
    >
    <template v-slot:top="{ pagination, options, updateOptions }">
      <v-toolbar
        v-if="displayTopPagination"
        flat
        class="mr-0 pr-0"
      >
        <v-spacer />
        <div>
          <v-data-footer
            :pagination="pagination"
            :options="options"
            @update:options="updateOptions"
            v-bind="getSettings.footerProps"
            items-per-page-text="$vuetify.dataTable.itemsPerPageText"
            :items-per-page.sync="getSettings.itemsperpage"
            :server-items-length="getSettings.serverItemsLength"
          />
        </div>
      </v-toolbar>
      <Toolbar :toolbarTitle="toolbarTitle">
        <template v-slot:[`additionalTools`]="{}">
          <slot name="additionalTools">
            <!-- can override to show other tools here -->
          </slot>
        </template>
        <template v-slot:[`selectDate`]="{}">
          <slot name="selectDate">
          </slot>
        </template>
        <template v-slot:activator="{}">
          <slot name="activator">
            <!-- can override to show other tools here -->
          </slot>
        </template>
        <template v-slot:[`bulkMarking`]="{}">
          <slot name="bulkMarking">
            <!-- can override to show other tools here -->
          </slot>
        </template>
        <template v-slot:[`duplicateMarking`]="{}">
          <slot name="duplicateMarking">
            <!-- can override to show other tools here -->
          </slot>
        </template>
        <template v-slot:[`canDeleteSlot`]="{}">
          <slot name="canDeleteSlot">
            <!-- can override to show other tools here -->
          </slot>
        </template>
        <template v-slot:[`additionalToolsAtRight`]="{}">
          <slot name="additionalToolsAtRight">
            <!-- can override to show other tools here -->
          </slot>
        </template>
        <template v-slot:[`canAddSlot`]="{}">
          <v-divider
            class="mx-4" inset vertical
          ></v-divider>
          <v-btn
            id="add-new-base-item"
            v-if="canAdd"
            class="mx-2" color="primary"
            fab icon small
            @click="handleNewItem"
            :disabled="isVisibleProp"
          >
            <v-icon>
              {{icons.mdiPlus}}
            </v-icon>
          </v-btn>
          <!-- TODO - multi-select support
          :disabled="selectedProp.length !== 0"
          -->
          <CRUDDialog
            ref="crudDialog"
            :baseCrudKey="baseCrudKey"
            :canSave="canSave"
            :canApply="canApply"
            @upsertItem="emitUpsertItem"
          >
            <template v-slot:[`editedItem`]="{}">
              <slot name="editedItem">
                <v-container>
                  <!-- you should always override to show how to edit the item -->
                  <span>(this is the default template)</span>
                </v-container>
              </slot>
            </template>
            <template v-slot:[`additionalActions`]="{}">
              <slot name="additionalActions"/>
            </template>
          </CRUDDialog>
        </template>
      </Toolbar>
    </template>

    <template v-slot:[`item`]="{ item, headers }">
      <Row
        :showSelect="showSelect"
        :selectedItems="selectedTasks"
        :canEdit="canEdit"
        :canDelete="canDelete"
        :item="item"
        :headers="headers"
        :uploadFileParams="uploadFileParams"
        :showExpand="showExpand"
        :expanded="expanded"
        @editItem="handleEditItem"
        @emitSelectedItem="handleSelectedTasks"
        @emitDeleteRowItem="deleteRowItem"
        @toggleExpandableRowItem="toggleExpandableRowItem"
      />
    </template>
      <template v-if="showExpand" v-slot:expanded-item="{ item }">
        <td :colspan="headers.length">
          <CRUDExpandableRow
            :item="item"
            :baseCrudKey="baseCrudKey"
          />
        </td>
      </template>
      <template
        v-slot:footer
        v-if="isShowTotal">
        <br/>
        <ListTotal
        :itemTotal="itemTotal"
        :customItemTotal="customItemTotal"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import {
  mdiPlus,
} from '@mdi/js';
import {
  mapGetters,
  mapMutations,
} from 'vuex';
import { isInsert } from '../../../../util/shared/vue-global';

import CRUDDialog from './CRUDDialog.vue';
import Toolbar from './CRUDToolbar.vue';
import Row from './CRUDRow.vue';
import ListTotal from './CRUDListTotal.vue';
import CRUDExpandableRow from './CRUDExpandableRow.vue';
import { getWorkstationNumber } from '../../../../util/workstation-util';

export default {
  name: 'CRUDList',
  components: {
    CRUDDialog,
    Toolbar,
    Row,
    ListTotal,
    CRUDExpandableRow,
  },
  props: {
    toolbarTitle: {
      type: String,
      default: 'Items',
    },
    itemKey: {
      type: String,
      default: '_id',
    },
    baseCrudKey: {
      type: String,
      default: 'default',
    },
    // the calling component has to tell us how to make a new item
    createNewItem: {
      type: Function,
      default: () => ({}),
    },
    headers: {
      type: Array,
      default: () => ([]),
    },
    dataMapper: {
      type: Function,
      default: (itemData) => itemData,
    },
    defaultSortBy: {
      type: Array,
      default: () => undefined,
    },
    canAdd: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    canSave: {
      type: Boolean,
      default: true,
    },
    canApply: {
      type: Boolean,
      default: false,
    },
    // if you don't want previous criteria pass false
    mergeCriteria: {
      type: Boolean,
      default: true,
    },
    uploadFileParams: {
      type: Object,
      default: () => ({}),
    },
    showSelect: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => ([]),
    },
    itemTotal: {
      type: Number,
      default: 0,
    },
    showTotal: {
      type: Boolean,
      default: false,
    },
    showExpand: {
      type: Boolean,
      default: false,
    },
    loadingData: {
      type: Boolean,
      default: false,
    },
    loadingText: {
      type: String,
      default: '',
    },
    customItemTotal: {
      type: String,
      default: '',
    },
    displayTopPagination: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    icons: {
      mdiPlus,
    },
    expanded: [],
  }),
  computed: {
    ...mapGetters('user', [
      'getSettings',
    ]),
    ...mapGetters('base/crud', [
      'items',
      'skipLimitPage',
      'isVisible',
    ]),
    selectedTasks: {
      get() {
        return this.selectedItems || [];
      },
      set(value) {
        this.$emit('emitSelectedItem', value);
      },
    },
    itemsGetter() {
      const itemData = this.items(this.baseCrudKey);
      return this.dataMapper(itemData);
    },
    isVisibleProp() {
      const visibleFlag = this.isVisible(this.baseCrudKey);
      if (visibleFlag === undefined) {
        return false;
      }
      return visibleFlag;
    },
    isShowTotal() {
      return this.showTotal && (this.itemTotal !== 0 || this.customItemTotal);
    },
  },
  created() {
    // first page default
    // const merge = true;
    const criteria = this.skipLimitPage(1);
    this.setCriteria([this.baseCrudKey, criteria, this.mergeCriteria]);
    // this.emitLoadCRUDList();
    // https://www.raymondcamden.com/2019/08/12/working-with-the-keyboard-in-your-vue-app
    window.addEventListener('keydown', this.shortcutHandler);
  },
  destroyed() {
    // https://stackoverflow.com/a/10444156/10567369
    window.removeEventListener('keydown', this.shortcutHandler);
  },
  methods: {
    ...mapMutations('base/crud', [
      'setCriteria',
      'setItem',
      'setIndex',
      'setIsVisible',
    ]),
    ...mapMutations(['setWkstDialog']),
    handlePage(page) { // To be remove - when 'handleOptions' become stable
      const merge = true;
      const criteria = this.skipLimitPage(page);
      this.setCriteria([this.baseCrudKey, criteria, merge]);
      this.emitLoadCRUDList();
    },
    buildSortCriteria(sortByOptions, sortDesc) {
      let sortBy = [];
      if (sortByOptions.length > 0 && sortDesc.length > 0
        && sortByOptions.length === sortDesc.length) {
        for (let i = 0; i < sortDesc.length; i += 1) {
          sortBy.push({
            column: sortByOptions[0],
            direction: sortDesc[0] ? 'desc' : 'asc',
          });
        }
      } else if (sortDesc.length === 0 || sortByOptions.length === 0) {
        if (!this.defaultSortBy) {
          console.warn('no default sortBy is set');
        }
        // default sort
        sortBy = this.defaultSortBy || [
          { column: '_id', direction: 'desc' },
        ];
      }
      return sortBy;
    },
    handleOptions(options) {
      const merge = true;
      const { sortBy: sortByOptions, sortDesc, page } = options;
      const paginationCriteria = this.skipLimitPage(page);
      const sortByCriteria = this.buildSortCriteria(sortByOptions, sortDesc);
      const criteria = { ...paginationCriteria, sortBy: sortByCriteria };
      this.setCriteria([this.baseCrudKey, criteria, merge]);
      this.emitLoadCRUDList();
    },
    handleDialogScrolling() {
      this.$nextTick(() => {
        const accessMultipleDialogBox = document.querySelectorAll('.v-dialog.v-dialog--active');
        for (let i = 0; i < accessMultipleDialogBox.length; i += 1) {
          accessMultipleDialogBox[i].scrollTop = 0;
        }
      });
    },
    handleNewItem() {
      /* Before opening a dialog, check that wkst is set or not? */
      const wkst = getWorkstationNumber();
      if (wkst) {
        const newItem = this.createNewItem();
        this.setItem([this.baseCrudKey, newItem]);
        this.setIndex([this.baseCrudKey, -1]);
        this.setIsVisible([this.baseCrudKey, true]);
        this.emitActivateItem(newItem);
        this.handleDialogScrolling();
      } else {
        this.setWkstDialog(true);
      }
    },
    handleEditItem(item) {
      const index = this.itemsGetter.indexOf(item);
      this.setItem([this.baseCrudKey, { ...item }]);
      this.setIndex([this.baseCrudKey, index]);
      this.setIsVisible([this.baseCrudKey, true]);
      this.emitActivateItem(item);
      this.handleDialogScrolling();
    },
    emitActivateItem(item) {
      this.$emit('activateItem', item);
    },
    emitLoadCRUDList() {
      this.$emit('loadCRUDList');
    },
    emitUpsertItem(item) {
      this.$emit('upsertItem', item);
    },
    // emitSelectedItem(item) {
    // },
    shortcutHandler(e) {
      if (this.canAdd && isInsert(e)) {
        this.handleNewItem();
        // important as per v-dialog documentation
        e.stopPropagation();
      }
    },
    handleSelectedTasks(value) {
      this.selectedTasks = value || [];
    },
    toggleExpandableRowItem(item) {
      const itemId = item._id;
      const filteredItem = (this.expanded || []).find((t) => t._id === itemId);
      if (!filteredItem) {
        this.expanded.push(item);
      } else {
        this.expanded = (this.expanded || []).filter((t) => t._id !== itemId);
      }
    },
    deleteRowItem(item) {
      this.$emit('emitDeleteRowItem', item);
    },
  },
};
</script>

<style lang="sass">
// overriding default VDataTable Margins
.v-data-table
  > .v-data-footer
    margin-right: 21px
    > .v-data-footer__select
      margin-right: 18px
    > .v-data-footer__icons-before
      margin-right: 9px
    > .v-data-footer__icons-after
      margin-left: 4px
</style>
