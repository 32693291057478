<template>
  <div>
    <v-dialog
      v-model="isVisibleProp"
      max-width="70vw"
      @keydown.enter="save"
    >
      <v-card class="CRUDDialog">
        <v-card-title>
          <span class="headline">{{ dialogTitle }}</span>
        </v-card-title>

        <v-card-text>
          <v-form v-model="isValidProp" ref="form">
            <slot name="editedItem">
              <v-container>
                <!-- you should always override to show how to edit the item -->
                <span>(this is the default template)</span>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="editedItem._id"
                      label="ID"
                      readonly
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </slot>
          </v-form>
        </v-card-text>

        <v-card-actions id="tmc-base-crud-actions">
          <v-spacer></v-spacer>
          <slot name="additionalActions"/>
          <v-btn id = "crud-closing-btn"
            :class="`tmc-base-crud-${canSave ? 'cancel' : 'close'}`"
            text color="blue darken-1"
            @click="close"
          >{{canSave ? 'Cancel' : 'Close'}}</v-btn>
          <v-btn v-if="canApply"
            :class="`tmc-base-crud-apply`"
            text color="blue darken-1"
            @click="apply"
          >Apply</v-btn>
          <v-btn v-if="canSave"
            :class="`tmc-base-crud-save`"
            text color="blue darken-1"
            @click="save"
          >Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiContentSave,
} from '@mdi/js';
import {
  mapGetters,
  mapMutations,
} from 'vuex';

export default {
  name: 'CRUDDialog',
  components: {
  },
  props: {
    baseCrudKey: {
      type: String,
      default: 'default',
    },
    canSave: {
      type: Boolean,
      default: true,
    },
    canApply: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    icons: {
      mdiContentSave,
    },
    isValidProp: false,
  }),
  computed: {
    ...mapGetters('base/crud', [
      'item',
      'index',
      'isVisible',
    ]),
    dialogTitle() {
      if (this.canSave === false) {
        return 'Item';
      }
      return this.index(this.baseCrudKey) === -1 ? 'New Item' : 'Edit Item';
    },
    isVisibleProp: {
      get() {
        return this.isVisible(this.baseCrudKey);
      },
      set(value) {
        this.setIsVisible([this.baseCrudKey, value]);
      },
    },
    editedItem: {
      get() {
        return this.item(this.baseCrudKey);
      },
      set(value) {
        this.setItem([this.baseCrudKey, value]);
      },
    },
  },
  methods: {
    ...mapMutations('base/crud', [
      'setIsVisible',
      'setItem',
    ]),
    validateForm() {
      return this.$refs.form.validate();
    },
    close() {
      this.isVisibleProp = false;
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem };
        this.editedIndex = -1;
      });
    },
    save(withClose = true) {
      if (!this.isValidProp) {
        this.$emit('invalidItem', this.editedItem);
        // makes the red UI validators show up
        this.$refs.form.validate();
      } else {
        this.$emit('upsertItem', this.editedItem);
        if (withClose) {
          this.close();
        }
      }
    },
    apply() {
      this.save(false);
    },
    // one more method saveOnEnter -> pass prop to not use default save on enter for GL lock feat
  },
};
</script>
